import { Link as DoczLink, Props } from 'docz';
import { Heading1, Heading2, Heading3, Heading4, Heading5, Heading6, LeadParagraph, Paragraph, SubParagraph, Label, SubLabel, SmallText, StrongText, EmphasizedText, Link, PreformattedText, CodeText, UnorderedList, NumberedList, ListItem, Blockquote, BlockquoteFooter } from '@entur/typography';
import { Playground } from '~/components/playground/Playground';
import PageHeader from '~/components/PageHeader';
import { ImportStatement } from '~/components/ImportStatement';
import * as React from 'react';
export default {
  DoczLink,
  Props,
  Heading1,
  Heading2,
  Heading3,
  Heading4,
  Heading5,
  Heading6,
  LeadParagraph,
  Paragraph,
  SubParagraph,
  Label,
  SubLabel,
  SmallText,
  StrongText,
  EmphasizedText,
  Link,
  PreformattedText,
  CodeText,
  UnorderedList,
  NumberedList,
  ListItem,
  Blockquote,
  BlockquoteFooter,
  Playground,
  PageHeader,
  ImportStatement,
  React
};