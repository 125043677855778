import React from 'react';

interface IconProps {
  color: string;
}

const TextIcon: React.FC<IconProps> = ({ color }) => (
  <svg
    width="23"
    height="29"
    viewBox="0 0 23 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <filter id="inset-shadow" x="-50%" y="-50%" width="200%" height="200%">
        <feComponentTransfer in="SourceAlpha">
          <feFuncA type="table" tableValues="1 0" />
        </feComponentTransfer>
        <feGaussianBlur stdDeviation="2" />
        <feOffset dx="0" dy="2" result="offsetblur" />
        <feFlood floodColor="rgba(0,0,0,0.2)" />
        <feComposite in2="offsetblur" operator="in" />
        <feComposite in2="SourceAlpha" operator="in" />
        <feMerge>
          <feMergeNode in="SourceGraphic" />
          <feMergeNode />
        </feMerge>
      </filter>
    </defs>
    <g filter="url(#inset-shadow)">
      <path
        d="M14.2 5.32V29H8.84V5.32H0.32V0.68H22.76V5.32H14.2Z"
        fill={color}
      />
    </g>
  </svg>
);

export default TextIcon;
