const testdata = [
  {
    name: 'Statusrapport Entur',
    date: '01.01.2020',
    category: 'Transaksjonsstatistikk',
    receiver: 'Entur Alle',
  },
  {
    name: 'Statusrapport Vy',
    date: '02.01.2020',
    category: 'Transaksjonsstatistikk',
    receiver: 'Vy alle',
  },
  {
    name: 'Ledelserapport Entur',
    date: '15.04.2020',
    category: 'Transaksjonsstatistikk',
    receiver: 'Entur Ledelse',
  },
  {
    name: 'Statusrapport Entur',
    date: '15.04.2020',
    category: 'Transaksjonsstatistikk',
    receiver: 'Entur Alle',
  },
  {
    name: 'Statusrapport Vy',
    date: '25.05.2020',
    category: 'Transaksjonsstatistikk',
    receiver: 'Vy alle',
  },
  {
    name: 'Ledelserapport Entur',
    date: '15.04.2020',
    category: 'Transaksjonsstatistikk',
    receiver: 'Entur Ledelse',
  },
  {
    name: 'Statusrapport Entur',
    date: '15.04.2020',
    category: 'Transaksjonsstatistikk',
    receiver: 'Entur Alle',
  },
  {
    name: 'Statusrapport Vy',
    date: '25.05.2020',
    category: 'Transaksjonsstatistikk',
    receiver: 'Vy alle',
  },
  {
    name: 'Ledelserapport Entur',
    date: '15.04.2020',
    category: 'Transaksjonsstatistikk',
    receiver: 'Entur Ledelse',
  },
  {
    name: 'Statusrapport Entur',
    date: '15.04.2020',
    category: 'Transaksjonsstatistikk',
    receiver: 'Entur Alle',
  },
  {
    name: 'Statusrapport Vy',
    date: '25.05.2020',
    category: 'Transaksjonsstatistikk',
    receiver: 'Vy alle',
  },
  {
    name: 'Ledelserapport Entur',
    date: '15.04.2020',
    category: 'Transaksjonsstatistikk',
    receiver: 'Entur Ledelse',
  },
  {
    name: 'Statusrapport Entur',
    date: '15.04.2020',
    category: 'Transaksjonsstatistikk',
    receiver: 'Entur Alle',
  },
  {
    name: 'Statusrapport Vy',
    date: '25.05.2020',
    category: 'Transaksjonsstatistikk',
    receiver: 'Vy alle',
  },
  {
    name: 'Ledelserapport Entur',
    date: '15.04.2020',
    category: 'Transaksjonsstatistikk',
    receiver: 'Entur Ledelse',
  },
  {
    name: 'Statusrapport Entur',
    date: '15.04.2020',
    category: 'Transaksjonsstatistikk',
    receiver: 'Entur Alle',
  },
  {
    name: 'Statusrapport Vy',
    date: '25.05.2020',
    category: 'Transaksjonsstatistikk',
    receiver: 'Vy alle',
  },
  {
    name: 'Ledelserapport Entur',
    date: '15.04.2020',
    category: 'Transaksjonsstatistikk',
    receiver: 'Entur Ledelse',
  },
  {
    name: 'Statusrapport Entur',
    date: '15.04.2020',
    category: 'Transaksjonsstatistikk',
    receiver: 'Entur Alle',
  },
  {
    name: 'Statusrapport Vy',
    date: '25.05.2020',
    category: 'Transaksjonsstatistikk',
    receiver: 'Vy alle',
  },
  {
    name: 'Ledelserapport Entur',
    date: '15.04.2020',
    category: 'Transaksjonsstatistikk',
    receiver: 'Entur Ledelse',
  },
  {
    name: 'Statusrapport Entur',
    date: '15.04.2020',
    category: 'Transaksjonsstatistikk',
    receiver: 'Entur Alle',
  },
  {
    name: 'Statusrapport Vy',
    date: '25.05.2020',
    category: 'Transaksjonsstatistikk',
    receiver: 'Vy alle',
  },
  {
    name: 'Ledelserapport Entur',
    date: '15.04.2020',
    category: 'Transaksjonsstatistikk',
    receiver: 'Entur Ledelse',
  },
  {
    name: 'Statusrapport Entur',
    date: '15.04.2020',
    category: 'Transaksjonsstatistikk',
    receiver: 'Entur Alle',
  },
  {
    name: 'Statusrapport Vy',
    date: '25.05.2020',
    category: 'Transaksjonsstatistikk',
    receiver: 'Vy alle',
  },
  {
    name: 'Ledelserapport Entur',
    date: '15.04.2020',
    category: 'Transaksjonsstatistikk',
    receiver: 'Entur Ledelse',
  },
  {
    name: 'Statusrapport Entur',
    date: '15.04.2020',
    category: 'Transaksjonsstatistikk',
    receiver: 'Entur Alle',
  },
  {
    name: 'Statusrapport Vy',
    date: '25.05.2020',
    category: 'Transaksjonsstatistikk',
    receiver: 'Vy alle',
  },
  {
    name: 'Ledelserapport Entur',
    date: '15.04.2020',
    category: 'Transaksjonsstatistikk',
    receiver: 'Entur Ledelse',
  },
  {
    name: 'Statusrapport Entur',
    date: '15.04.2020',
    category: 'Transaksjonsstatistikk',
    receiver: 'Entur Alle',
  },
  {
    name: 'Statusrapport Vy',
    date: '25.05.2020',
    category: 'Transaksjonsstatistikk',
    receiver: 'Vy alle',
  },
  {
    name: 'Ledelserapport Entur',
    date: '15.04.2020',
    category: 'Transaksjonsstatistikk',
    receiver: 'Entur Ledelse',
  },
  {
    name: 'Statusrapport Entur',
    date: '15.04.2020',
    category: 'Transaksjonsstatistikk',
    receiver: 'Entur Alle',
  },
  {
    name: 'Statusrapport Vy',
    date: '25.05.2020',
    category: 'Transaksjonsstatistikk',
    receiver: 'Vy alle',
  },
  {
    name: 'Ledelserapport Entur',
    date: '15.04.2020',
    category: 'Transaksjonsstatistikk',
    receiver: 'Entur Ledelse',
  },
  {
    name: 'Statusrapport Entur',
    date: '15.04.2020',
    category: 'Transaksjonsstatistikk',
    receiver: 'Entur Alle',
  },
  {
    name: 'Statusrapport Vy',
    date: '25.05.2020',
    category: 'Transaksjonsstatistikk',
    receiver: 'Vy alle',
  },
  {
    name: 'Ledelserapport Entur',
    date: '15.04.2020',
    category: 'Transaksjonsstatistikk',
    receiver: 'Entur Ledelse',
  },
  {
    name: 'Statusrapport Entur',
    date: '15.04.2020',
    category: 'Transaksjonsstatistikk',
    receiver: 'Entur Alle',
  },
  {
    name: 'Statusrapport Vy',
    date: '25.05.2020',
    category: 'Transaksjonsstatistikk',
    receiver: 'Vy alle',
  },
  {
    name: 'Ledelserapport Entur',
    date: '15.04.2020',
    category: 'Transaksjonsstatistikk',
    receiver: 'Entur Ledelse',
  },
  {
    name: 'Statusrapport Entur',
    date: '15.04.2020',
    category: 'Transaksjonsstatistikk',
    receiver: 'Entur Alle',
  },
  {
    name: 'Statusrapport Vy',
    date: '25.05.2020',
    category: 'Transaksjonsstatistikk',
    receiver: 'Vy alle',
  },
  {
    name: 'Ledelserapport Entur',
    date: '15.04.2020',
    category: 'Transaksjonsstatistikk',
    receiver: 'Entur Ledelse',
  },
  {
    name: 'Statusrapport Entur',
    date: '15.04.2020',
    category: 'Transaksjonsstatistikk',
    receiver: 'Entur Alle',
  },
  {
    name: 'Statusrapport Vy',
    date: '25.05.2020',
    category: 'Transaksjonsstatistikk',
    receiver: 'Vy alle',
  },
  {
    name: 'Ledelserapport Entur',
    date: '15.04.2020',
    category: 'Transaksjonsstatistikk',
    receiver: 'Entur Ledelse',
  },
  {
    name: 'Statusrapport Entur',
    date: '15.04.2020',
    category: 'Transaksjonsstatistikk',
    receiver: 'Entur Alle',
  },
  {
    name: 'Statusrapport Vy',
    date: '25.05.2020',
    category: 'Transaksjonsstatistikk',
    receiver: 'Vy alle',
  },
  {
    name: 'Ledelserapport Entur',
    date: '15.04.2020',
    category: 'Transaksjonsstatistikk',
    receiver: 'Entur Ledelse',
  },
  {
    name: 'Statusrapport Entur',
    date: '15.04.2020',
    category: 'Transaksjonsstatistikk',
    receiver: 'Entur Alle',
  },
  {
    name: 'Statusrapport Vy',
    date: '25.05.2020',
    category: 'Transaksjonsstatistikk',
    receiver: 'Vy alle',
  },
  {
    name: 'Ledelserapport Entur',
    date: '15.04.2020',
    category: 'Transaksjonsstatistikk',
    receiver: 'Entur Ledelse',
  },
  {
    name: 'Statusrapport Entur',
    date: '15.04.2020',
    category: 'Transaksjonsstatistikk',
    receiver: 'Entur Alle',
  },
  {
    name: 'Statusrapport Vy',
    date: '25.05.2020',
    category: 'Transaksjonsstatistikk',
    receiver: 'Vy alle',
  },
  {
    name: 'Ledelserapport Entur',
    date: '15.04.2020',
    category: 'Transaksjonsstatistikk',
    receiver: 'Entur Ledelse',
  },
  {
    name: 'Statusrapport Entur',
    date: '15.04.2020',
    category: 'Transaksjonsstatistikk',
    receiver: 'Entur Alle',
  },
  {
    name: 'Statusrapport Vy',
    date: '25.05.2020',
    category: 'Transaksjonsstatistikk',
    receiver: 'Vy alle',
  },
  {
    name: 'Ledelserapport Entur',
    date: '15.04.2020',
    category: 'Transaksjonsstatistikk',
    receiver: 'Entur Ledelse',
  },
  {
    name: 'Statusrapport Entur',
    date: '15.04.2020',
    category: 'Transaksjonsstatistikk',
    receiver: 'Entur Alle',
  },
  {
    name: 'Statusrapport Vy',
    date: '25.05.2020',
    category: 'Transaksjonsstatistikk',
    receiver: 'Vy alle',
  },
  {
    name: 'Ledelserapport Entur',
    date: '15.04.2020',
    category: 'Transaksjonsstatistikk',
    receiver: 'Entur Ledelse',
  },
  {
    name: 'Statusrapport Entur',
    date: '15.04.2020',
    category: 'Transaksjonsstatistikk',
    receiver: 'Entur Alle',
  },
  {
    name: 'Statusrapport Vy',
    date: '25.05.2020',
    category: 'Transaksjonsstatistikk',
    receiver: 'Vy alle',
  },
  {
    name: 'Ledelserapport Entur',
    date: '15.04.2020',
    category: 'Transaksjonsstatistikk',
    receiver: 'Entur Ledelse',
  },
  {
    name: 'Statusrapport Entur',
    date: '15.04.2020',
    category: 'Transaksjonsstatistikk',
    receiver: 'Entur Alle',
  },
  {
    name: 'Statusrapport Vy',
    date: '25.05.2020',
    category: 'Transaksjonsstatistikk',
    receiver: 'Vy alle',
  },
  {
    name: 'Ledelserapport Entur',
    date: '15.04.2020',
    category: 'Transaksjonsstatistikk',
    receiver: 'Entur Ledelse',
  },
  {
    name: 'Statusrapport Entur',
    date: '15.04.2020',
    category: 'Transaksjonsstatistikk',
    receiver: 'Entur Alle',
  },
  {
    name: 'Statusrapport Vy',
    date: '25.05.2020',
    category: 'Transaksjonsstatistikk',
    receiver: 'Vy alle',
  },
  {
    name: 'Ledelserapport Entur',
    date: '15.04.2020',
    category: 'Transaksjonsstatistikk',
    receiver: 'Entur Ledelse',
  },
  {
    name: 'Statusrapport Entur',
    date: '15.04.2020',
    category: 'Transaksjonsstatistikk',
    receiver: 'Entur Alle',
  },
  {
    name: 'Statusrapport Vy',
    date: '25.05.2020',
    category: 'Transaksjonsstatistikk',
    receiver: 'Vy alle',
  },
  {
    name: 'Ledelserapport Entur',
    date: '15.04.2020',
    category: 'Transaksjonsstatistikk',
    receiver: 'Entur Ledelse',
  },
  {
    name: 'Statusrapport Entur',
    date: '15.04.2020',
    category: 'Transaksjonsstatistikk',
    receiver: 'Entur Alle',
  },
  {
    name: 'Statusrapport Vy',
    date: '25.05.2020',
    category: 'Transaksjonsstatistikk',
    receiver: 'Vy alle',
  },
  {
    name: 'Ledelserapport Entur',
    date: '15.04.2020',
    category: 'Transaksjonsstatistikk',
    receiver: 'Entur Ledelse',
  },
  {
    name: 'Statusrapport Entur',
    date: '15.04.2020',
    category: 'Transaksjonsstatistikk',
    receiver: 'Entur Alle',
  },
  {
    name: 'Statusrapport Vy',
    date: '25.05.2020',
    category: 'Transaksjonsstatistikk',
    receiver: 'Vy alle',
  },
  {
    name: 'Ledelserapport Entur',
    date: '15.04.2020',
    category: 'Transaksjonsstatistikk',
    receiver: 'Entur Ledelse',
  },
  {
    name: 'Statusrapport Entur',
    date: '15.04.2020',
    category: 'Transaksjonsstatistikk',
    receiver: 'Entur Alle',
  },
  {
    name: 'Statusrapport Vy',
    date: '25.05.2020',
    category: 'Transaksjonsstatistikk',
    receiver: 'Vy alle',
  },
  {
    name: 'Ledelserapport Entur',
    date: '15.04.2020',
    category: 'Transaksjonsstatistikk',
    receiver: 'Entur Ledelse',
  },
  {
    name: 'Statusrapport Entur',
    date: '15.04.2020',
    category: 'Transaksjonsstatistikk',
    receiver: 'Entur Alle',
  },
  {
    name: 'Statusrapport Vy',
    date: '25.05.2020',
    category: 'Transaksjonsstatistikk',
    receiver: 'Vy alle',
  },
  {
    name: 'Ledelserapport Entur',
    date: '15.04.2020',
    category: 'Transaksjonsstatistikk',
    receiver: 'Entur Ledelse',
  },
  {
    name: 'Statusrapport Entur',
    date: '15.04.2020',
    category: 'Transaksjonsstatistikk',
    receiver: 'Entur Alle',
  },
  {
    name: 'Statusrapport Vy',
    date: '25.05.2020',
    category: 'Transaksjonsstatistikk',
    receiver: 'Vy alle',
  },
  {
    name: 'Ledelserapport Entur',
    date: '15.04.2020',
    category: 'Transaksjonsstatistikk',
    receiver: 'Entur Ledelse',
  },
  {
    name: 'Statusrapport Entur',
    date: '15.04.2020',
    category: 'Transaksjonsstatistikk',
    receiver: 'Entur Alle',
  },
  {
    name: 'Statusrapport Vy',
    date: '25.05.2020',
    category: 'Transaksjonsstatistikk',
    receiver: 'Vy alle',
  },
  {
    name: 'Ledelserapport Entur',
    date: '15.04.2020',
    category: 'Transaksjonsstatistikk',
    receiver: 'Entur Ledelse',
  },
  {
    name: 'Statusrapport Entur',
    date: '15.04.2020',
    category: 'Transaksjonsstatistikk',
    receiver: 'Entur Alle',
  },
  {
    name: 'Statusrapport Vy',
    date: '25.05.2020',
    category: 'Transaksjonsstatistikk',
    receiver: 'Vy alle',
  },
  {
    name: 'Ledelserapport Entur',
    date: '15.04.2020',
    category: 'Transaksjonsstatistikk',
    receiver: 'Entur Ledelse',
  },
  {
    name: 'Statusrapport Entur',
    date: '15.04.2020',
    category: 'Transaksjonsstatistikk',
    receiver: 'Entur Alle',
  },
  {
    name: 'Statusrapport Vy',
    date: '25.05.2020',
    category: 'Transaksjonsstatistikk',
    receiver: 'Vy alle',
  },
  {
    name: 'Ledelserapport Entur',
    date: '15.04.2020',
    category: 'Transaksjonsstatistikk',
    receiver: 'Entur Ledelse',
  },
  {
    name: 'Statusrapport Entur',
    date: '10.01.2020',
    category: 'Transaksjonsstatistikk',
    receiver: 'Entur Alle',
  },
  {
    name: 'Statusrapport Vy',
    date: '10.01.2020',
    category: 'Transaksjonsstatistikk',
    receiver: 'Vy alle',
  },
  {
    name: 'Ledelserapport Entur',
    date: '10.02.2020',
    category: 'Transaksjonsstatistikk',
    receiver: 'Entur Ledelse',
  },
];

export default testdata;
