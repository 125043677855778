export const topnavigation = [
  {
    name: 'children',
    defaultValue: 'Komponenter',
    type: 'string',
    label: 'Innhold',
  },
  {
    name: 'active',
    defaultValue: true,
    type: 'boolean',
  },
];
