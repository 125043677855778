import PageHeader from '~/components/PageHeader';
import { TokenTable } from '~/components/TokensTable/TokenTable';
import { colors, borderWidths, space } from '@entur/tokens';
import * as React from 'react';
export default {
  PageHeader,
  TokenTable,
  colors,
  borderWidths,
  space,
  React
};