import { Props, Link } from 'docz';
import { TravelHeader } from '@entur/travel';
import { Playground } from '~/components/playground/Playground';
import PageHeader from '~/components/PageHeader';
import { ImportStatement } from '~/components/ImportStatement';
import { colors } from '@entur/tokens';
import ex1 from "../../../../../content/components/reise/TravelHeaderExample.png";
import { travelheader } from "../../../../../content/components/reise/props";
import * as React from 'react';
export default {
  Props,
  Link,
  TravelHeader,
  Playground,
  PageHeader,
  ImportStatement,
  colors,
  ex1,
  travelheader,
  React
};