import { Props } from 'docz';
import { SecondarySquareButton, SuccessSquareButton, TertiarySquareButton } from '@entur/button';
import { AddIcon, CheckIcon } from '@entur/icons';
import { Playground } from '~/components/playground/Playground';
import PageHeader from '~/components/PageHeader';
import { ImportStatement } from '~/components/ImportStatement';
import * as React from 'react';
export default {
  Props,
  SecondarySquareButton,
  SuccessSquareButton,
  TertiarySquareButton,
  AddIcon,
  CheckIcon,
  Playground,
  PageHeader,
  ImportStatement,
  React
};