import PageHeader from '~/components/PageHeader';
import { Heading2, Heading3, Heading4, Paragraph } from '@entur/typography';
import { PurposeIcon, ViewIcon, ValueIcon } from '@entur/icons';
import { GridContainer, GridItem } from '@entur/grid';
import { Contrast } from '@entur/layout';
import "../../../../../content/identitet/introduksjon/merkevareplattform.scss";
import * as React from 'react';
export default {
  PageHeader,
  Heading2,
  Heading3,
  Heading4,
  Paragraph,
  PurposeIcon,
  ViewIcon,
  ValueIcon,
  GridContainer,
  GridItem,
  Contrast,
  React
};