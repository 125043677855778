import { Props } from 'docz';
import { RadioPanel, RadioGroup } from '@entur/form';
import { ExpandableText } from '@entur/expand';
import { VyIcon } from '@entur/icons';
import { Label } from '@entur/typography';
import { Playground } from '~/components/playground/Playground';
import { ImportStatement } from '~/components/ImportStatement';
import PageHeader from '~/components/PageHeader';
import { inputpanel } from "../../../../../content/components/skjemaelementer/props";
import * as React from 'react';
export default {
  Props,
  RadioPanel,
  RadioGroup,
  ExpandableText,
  VyIcon,
  Label,
  Playground,
  ImportStatement,
  PageHeader,
  inputpanel,
  React
};