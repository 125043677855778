import { graphql } from 'gatsby';
import { Heading5, Paragraph } from '@entur/typography';
import { DoDontGroup, DoDontCard } from '~/components/DoDont';
import PageHeader from '~/components/PageHeader';
import { ImageDisplay } from '~/components/ImageDisplay';
import BaseCard from '~/components/BaseCardDesignEntur';
import * as React from 'react';
export default {
  graphql,
  Heading5,
  Paragraph,
  DoDontGroup,
  DoDontCard,
  PageHeader,
  ImageDisplay,
  BaseCard,
  React
};