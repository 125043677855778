import { Props, Link } from 'docz';
import { Link as LinkText } from '@entur/typography';
import { TextField } from '@entur/form';
import { GridItem, GridContainer } from '@entur/grid';
import { SearchIcon } from '@entur/icons';
import { Playground } from '~/components/playground/Playground';
import PageHeader from '~/components/PageHeader';
import { ImportStatement } from '~/components/ImportStatement';
import { textfield } from "../../../../../content/components/skjemaelementer/props";
import * as React from 'react';
export default {
  Props,
  Link,
  LinkText,
  TextField,
  GridItem,
  GridContainer,
  SearchIcon,
  Playground,
  PageHeader,
  ImportStatement,
  textfield,
  React
};