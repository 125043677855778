import PageHeader from '~/components/PageHeader';
import { DownloadEpostSignature } from '~/gatsby-theme-docz/components/DownloadEpostSignature';
import { Paragraph, StrongText } from '@entur/typography';
import { colors } from '@entur/tokens';
import EmailBilde from "../../../../../content/identitet/maler/Email_signatur.png";
import "../../../../../content/identitet/maler/index.scss";
import * as React from 'react';
export default {
  PageHeader,
  DownloadEpostSignature,
  Paragraph,
  StrongText,
  colors,
  EmailBilde,
  React
};