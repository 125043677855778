import { Props } from 'docz';
import { ImportStatement } from '~/components/ImportStatement';
import PageHeader from '~/components/PageHeader';
import { Playground } from '~/components/playground/Playground';
import { Stepper } from '@entur/menu';
import { IconButton } from '@entur/button';
import { Label } from '@entur/typography';
import * as React from 'react';
export default {
  Props,
  ImportStatement,
  PageHeader,
  Playground,
  Stepper,
  IconButton,
  Label,
  React
};