import PageHeader from '~/components/PageHeader';
import Bus from "../../../../../content/kom-i-gang/introduksjon/Bus.png";
import City from "../../../../../content/kom-i-gang/introduksjon/City.png";
import TrainLong from "../../../../../content/kom-i-gang/introduksjon/TrainLong.png";
import "../../../../../content/kom-i-gang/introduksjon/om-entur.scss";
import * as React from 'react';
export default {
  PageHeader,
  Bus,
  City,
  TrainLong,
  React
};