import { Props, Link } from 'docz';
import { Pagination } from '@entur/menu';
import { Playground } from '~/components/playground/Playground';
import PageHeader from '~/components/PageHeader';
import { Paragraph, Link as LinkText } from '@entur/typography';
import { Table, TableHead, TableBody, TableFooter, TableRow, HeaderCell, DataCell, useSortableData } from '@entur/table';
import { ImportStatement } from '~/components/ImportStatement';
import testdata from "../../../../../content/components/layout-og-flater/testdata";
import * as React from 'react';
export default {
  Props,
  Link,
  Pagination,
  Playground,
  PageHeader,
  Paragraph,
  LinkText,
  Table,
  TableHead,
  TableBody,
  TableFooter,
  TableRow,
  HeaderCell,
  DataCell,
  useSortableData,
  ImportStatement,
  testdata,
  React
};