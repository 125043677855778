import { NavigationCard, BaseCard } from '@entur/layout';
import { Props } from 'docz';
import { Playground } from '~/components/playground/Playground';
import PageHeader from '~/components/PageHeader';
import { ImportStatement } from '~/components/ImportStatement';
import { RSSIcon, MapPinIcon, InterrailIcon } from '@entur/icons';
import { cards } from "../../../../../content/components/layout-og-flater/props";
import * as React from 'react';
export default {
  NavigationCard,
  BaseCard,
  Props,
  Playground,
  PageHeader,
  ImportStatement,
  RSSIcon,
  MapPinIcon,
  InterrailIcon,
  cards,
  React
};