import PageHeader from '~/components/PageHeader';
import reisebehov from "../../../../../content/kom-i-gang/for-designere/images/reisebehov.png";
import Brukergrupper from "../../../../../content/kom-i-gang/for-designere/images/Brukergrupper.png";
import SituasjonsBehov from "../../../../../content/kom-i-gang/for-designere/images/SituasjonsBehov.png";
import { GridContainer, GridItem } from '@entur/grid';
import { TrainIcon, BusIcon, MobileIcon, StandingIcon } from '@entur/icons';
import { Paragraph } from '@entur/typography';
import { VisuallyHidden } from '@entur/a11y';
import "../../../../../content/kom-i-gang/for-designere/brukergrupper.scss";
import * as React from 'react';
export default {
  PageHeader,
  reisebehov,
  Brukergrupper,
  SituasjonsBehov,
  GridContainer,
  GridItem,
  TrainIcon,
  BusIcon,
  MobileIcon,
  StandingIcon,
  Paragraph,
  VisuallyHidden,
  React
};