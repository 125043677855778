import { Tag } from '@entur/layout';
import { Props } from 'docz';
import { Playground } from '~/components/playground/Playground';
import PageHeader from '~/components/PageHeader';
import { ImportStatement } from '~/components/ImportStatement';
import { ClockIcon } from '@entur/icons';
import * as React from 'react';
export default {
  Tag,
  Props,
  Playground,
  PageHeader,
  ImportStatement,
  ClockIcon,
  React
};