import { Props } from 'docz';
import { Playground } from '~/components/playground/Playground';
import PageHeader from '~/components/PageHeader';
import InlineComponent from '~/components/InlineComponent';
import { DoDontGroup, DoDontCard } from '~/components/DoDont';
import { ImportStatement } from '~/components/ImportStatement';
import { SkeletonRectangle, SkeletonCircle, SkeletonWrapper } from '@entur/loader';
import * as React from 'react';
export default {
  Props,
  Playground,
  PageHeader,
  InlineComponent,
  DoDontGroup,
  DoDontCard,
  ImportStatement,
  SkeletonRectangle,
  SkeletonCircle,
  SkeletonWrapper,
  React
};