import { Props } from 'docz';
import { Badge, NotificationBadge, StatusBadge, BulletBadge } from '@entur/layout';
import { Tabs, TabList, Tab, TabPanels, TabPanel } from '@entur/tab';
import { Table, TableHead, TableBody, TableRow, HeaderCell, DataCell } from '@entur/table';
import { VisuallyHidden } from '@entur/a11y';
import { SecondarySquareButton, NegativeButton } from '@entur/button';
import { Heading3, Heading2, Paragraph } from '@entur/typography';
import { PrimaryButton } from '@entur/button';
import { TextField, TextArea } from '@entur/form';
import { Playground } from '~/components/playground/Playground';
import PageHeader from '~/components/PageHeader';
import { ImportStatement } from '~/components/ImportStatement';
import { SubtractIcon } from '@entur/icons';
import { badgeprops } from "../../../../../content/components/feedback/props";
import * as React from 'react';
export default {
  Props,
  Badge,
  NotificationBadge,
  StatusBadge,
  BulletBadge,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Table,
  TableHead,
  TableBody,
  TableRow,
  HeaderCell,
  DataCell,
  VisuallyHidden,
  SecondarySquareButton,
  NegativeButton,
  Heading3,
  Heading2,
  Paragraph,
  PrimaryButton,
  TextField,
  TextArea,
  Playground,
  PageHeader,
  ImportStatement,
  SubtractIcon,
  badgeprops,
  React
};