import { Props, Link } from 'docz';
import { TextArea } from '@entur/form';
import { ImageIcon } from '@entur/icons';
import { Playground } from '~/components/playground/Playground';
import { Link as LinkText } from '@entur/typography';
import PageHeader from '~/components/PageHeader';
import { ImportStatement } from '~/components/ImportStatement';
import { textarea } from "../../../../../content/components/skjemaelementer/props";
import * as React from 'react';
export default {
  Props,
  Link,
  TextArea,
  ImageIcon,
  Playground,
  LinkText,
  PageHeader,
  ImportStatement,
  textarea,
  React
};