import PageHeader from '~/components/PageHeader';
import { DesignPrinciple } from '~/components/DesignPrinciple';
import { PurposeIcon, AccessibilityIcon, DestinationIcon, ValueIcon, MapIcon } from '@entur/icons';
import { GridContainer } from '@entur/grid';
import * as React from 'react';
export default {
  PageHeader,
  DesignPrinciple,
  PurposeIcon,
  AccessibilityIcon,
  DestinationIcon,
  ValueIcon,
  MapIcon,
  GridContainer,
  React
};