import PageHeader from '~/components/PageHeader';
import { HvaViGjor } from '~/components/HvaViGjor';
import { GridItem, GridContainer } from '@entur/grid';
import Regjeringen from "../../../../../content/kom-i-gang/introduksjon/Regjeringen.png";
import "../../../../../content/kom-i-gang/introduksjon/hva-vi-gjor.scss";
import * as React from 'react';
export default {
  PageHeader,
  HvaViGjor,
  GridItem,
  GridContainer,
  Regjeringen,
  React
};