import PageHeader from '~/components/PageHeader';
import { GridItem, GridContainer } from '@entur/grid';
import { colors } from '@entur/tokens';
import { Paragraph, Heading3, Heading5 } from '@entur/typography';
import FigmaLogo from "../../../../../content/kom-i-gang/for-designere/images/FigmaLogo.png";
import MuralLogo from "../../../../../content/kom-i-gang/for-designere/images/MuralLogo.png";
import SlackLogo from "../../../../../content/kom-i-gang/for-designere/images/SlackLogo.png";
import * as React from 'react';
export default {
  PageHeader,
  GridItem,
  GridContainer,
  colors,
  Paragraph,
  Heading3,
  Heading5,
  FigmaLogo,
  MuralLogo,
  SlackLogo,
  React
};