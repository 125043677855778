import { Link, Props } from 'docz';
import { CodeText } from '@entur/typography';
import { SkipToContent, VisuallyHidden } from '@entur/a11y';
import PageHeader from '~/components/PageHeader';
import { Playground } from '~/components/playground/Playground';
import { ImportStatement } from '~/components/ImportStatement';
import * as React from 'react';
export default {
  Link,
  Props,
  CodeText,
  SkipToContent,
  VisuallyHidden,
  PageHeader,
  Playground,
  ImportStatement,
  React
};