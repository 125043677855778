import PageHeader from '~/components/PageHeader';
import { VisuallyHidden } from '@entur/a11y';
import { GridContainer, GridItem } from '@entur/grid';
import { ListItem, UnorderedList, Heading3, StrongText } from '@entur/typography';
import { BannerAlertBox } from '@entur/alert';
import arrows from "../../../../../content/identitet/introduksjon/arrows.png";
import { ExpandablePanel } from '@entur/expand';
import "../../../../../content/identitet/introduksjon/toneofvoice.scss";
import * as React from 'react';
export default {
  PageHeader,
  VisuallyHidden,
  GridContainer,
  GridItem,
  ListItem,
  UnorderedList,
  Heading3,
  StrongText,
  BannerAlertBox,
  arrows,
  ExpandablePanel,
  React
};