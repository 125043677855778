import { Props, Link } from 'docz';
import { TravelSwitch } from '@entur/travel';
import { Playground } from '~/components/playground/Playground';
import PageHeader from '~/components/PageHeader';
import { ImportStatement } from '~/components/ImportStatement';
import { travelswitch } from "../../../../../content/components/reise/props";
import "../../../../../content/components/reise/traveltag.css";
import * as React from 'react';
export default {
  Props,
  Link,
  TravelSwitch,
  Playground,
  PageHeader,
  ImportStatement,
  travelswitch,
  React
};