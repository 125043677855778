import { Props } from 'docz';
import { Link } from '@entur/typography';
import { Checkbox, Fieldset } from '@entur/form';
import { ImportStatement } from '~/components/ImportStatement';
import { Playground } from '~/components/playground/Playground';
import { DoDontGroup, DoDontCard } from '~/components/DoDont';
import doPic from "../../../../../content/components/skjemaelementer/CheckboxDo.png";
import dontPic from "../../../../../content/components/skjemaelementer/CheckboxDont.png";
import PageHeader from '~/components/PageHeader';
import * as React from 'react';
export default {
  Props,
  Link,
  Checkbox,
  Fieldset,
  ImportStatement,
  Playground,
  DoDontGroup,
  DoDontCard,
  doPic,
  dontPic,
  PageHeader,
  React
};