import { Props } from 'docz';
import { Switch } from '@entur/form';
import { Label } from '@entur/typography';
import { colors } from '@entur/tokens';
import { BusIcon, FerryIcon } from '@entur/icons';
import { Playground } from '~/components/playground/Playground';
import { ImportStatement } from '~/components/ImportStatement';
import PageHeader from '~/components/PageHeader';
import { DoDontGroup, DoDontCard } from '~/components/DoDont';
import doPic from "../../../../../content/components/skjemaelementer/SwitchDo.png";
import dontPic from "../../../../../content/components/skjemaelementer/SwitchDont.png";
import * as React from 'react';
export default {
  Props,
  Switch,
  Label,
  colors,
  BusIcon,
  FerryIcon,
  Playground,
  ImportStatement,
  PageHeader,
  DoDontGroup,
  DoDontCard,
  doPic,
  dontPic,
  React
};