import { Props } from 'docz';
import { Playground } from '~/components/playground/Playground';
import { PrimaryButton } from '@entur/button';
import { ExpandableText } from '@entur/expand';
import { Heading2, CodeText } from '@entur/typography';
import { Table, TableHead, TableBody, TableRow, HeaderCell, DataCell } from '@entur/table';
import { AddIcon } from '@entur/icons';
import * as icons from '@entur/icons';
import IconList from '~/components/IconList';
import PageHeader from '~/components/PageHeader';
import { DoDontGroup, DoDontCard } from '~/components/DoDont';
import alignDoSrc from "../../../../../content/components/ressurser/ikoner/align-do.png";
import alignDontSrc from "../../../../../content/components/ressurser/ikoner/align-dont.png";
import colorDoSrc from "../../../../../content/components/ressurser/ikoner/color-do.png";
import colorDontSrc from "../../../../../content/components/ressurser/ikoner/color-dont.png";
import sizeDoSrc from "../../../../../content/components/ressurser/ikoner/size-do.png";
import sizeDontSrc from "../../../../../content/components/ressurser/ikoner/size-dont.png";
import * as React from 'react';
export default {
  Props,
  Playground,
  PrimaryButton,
  ExpandableText,
  Heading2,
  CodeText,
  Table,
  TableHead,
  TableBody,
  TableRow,
  HeaderCell,
  DataCell,
  AddIcon,
  icons,
  IconList,
  PageHeader,
  DoDontGroup,
  DoDontCard,
  alignDoSrc,
  alignDontSrc,
  colorDoSrc,
  colorDontSrc,
  sizeDoSrc,
  sizeDontSrc,
  React
};