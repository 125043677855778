import { Props, Link as DoczLink } from 'docz';
import { Playground } from '~/components/playground/Playground';
import PageHeader from '~/components/PageHeader';
import { ImportStatement } from '~/components/ImportStatement';
import { CopyableText } from '@entur/alert';
import { Paragraph, PreformattedText, Link } from '@entur/typography';
import * as React from 'react';
export default {
  Props,
  DoczLink,
  Playground,
  PageHeader,
  ImportStatement,
  CopyableText,
  Paragraph,
  PreformattedText,
  Link,
  React
};