import { Props, Link } from 'docz';
import { LegLine, LegBone, TravelLeg } from '@entur/travel';
import { Playground } from '~/components/playground/Playground';
import PageHeader from '~/components/PageHeader';
import { ImportStatement } from '~/components/ImportStatement';
import { GridContainer, GridItem } from '@entur/grid';
import { colors } from '@entur/tokens';
import ex1 from "../../../../../content/components/reise/LegsExample1.png";
import ex2 from "../../../../../content/components/reise/LegsExample2.png";
import { travelleg } from "../../../../../content/components/reise/props";
import * as React from 'react';
export default {
  Props,
  Link,
  LegLine,
  LegBone,
  TravelLeg,
  Playground,
  PageHeader,
  ImportStatement,
  GridContainer,
  GridItem,
  colors,
  ex1,
  ex2,
  travelleg,
  React
};