import PageHeader from '~/components/PageHeader';
import BaseCardDesignEntur from '~/components/BaseCardDesignEntur';
import lightdarkmode from "../../../../../content/tokens/fargetokens/images/lightdarkmode.png";
import lightdarkmodecontrast from "../../../../../content/tokens/fargetokens/images/lightDarkmodeContrast.png";
import darkmodedemo from "../../../../../content/tokens/fargetokens/images/darkmodedemo.gif";
import componentExample from "../../../../../content/tokens/fargetokens/images/componentExample.png";
import componentExampleDark from "../../../../../content/tokens/fargetokens/images/componentExampleDark.png";
import illustrationExample from "../../../../../content/tokens/fargetokens/images/illustrationExample.png";
import illustrationExampleDark from "../../../../../content/tokens/fargetokens/images/illustrationExampleDark.png";
import { Heading4 } from '@entur/typography';
import * as React from 'react';
export default {
  PageHeader,
  BaseCardDesignEntur,
  lightdarkmode,
  lightdarkmodecontrast,
  darkmodedemo,
  componentExample,
  componentExampleDark,
  illustrationExample,
  illustrationExampleDark,
  Heading4,
  React
};