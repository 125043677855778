import { Props } from 'docz';
import { Link } from 'gatsby';
import { TopNavigationItem } from '@entur/menu';
import { LeadParagraph } from '@entur/typography';
import { UserIcon, StatsIcon, SettingsIcon } from '@entur/icons';
import { Playground } from '~/components/playground/Playground';
import PageHeader from '~/components/PageHeader';
import { ImportStatement } from '~/components/ImportStatement';
import { topnavigation } from "../../../../../content/components/navigasjon/props";
import * as React from 'react';
export default {
  Props,
  Link,
  TopNavigationItem,
  LeadParagraph,
  UserIcon,
  StatsIcon,
  SettingsIcon,
  Playground,
  PageHeader,
  ImportStatement,
  topnavigation,
  React
};