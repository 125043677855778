import { colors, borderRadiuses } from '@entur/tokens';
import { TokenTable } from '~/components/TokensTable/TokenTable';
import PageHeader from '~/components/PageHeader';
import { Table, TableHead, TableRow, TableBody, DataCell, HeaderCell } from '@entur/table';
import * as React from 'react';
export default {
  colors,
  borderRadiuses,
  TokenTable,
  PageHeader,
  Table,
  TableHead,
  TableRow,
  TableBody,
  DataCell,
  HeaderCell,
  React
};