import ContrastChecker from '~/components/ContrastChecker';
import PageHeader from '~/components/PageHeader';
import { Heading3, Paragraph } from '@entur/typography';
import { colors } from '@entur/tokens';
import { GridItem, GridContainer } from '@entur/grid';
import { ValidationErrorFilledIcon, ValidationSuccessFilledIcon } from '@entur/icons';
import "../../../../../content/universell-utforming/farger.scss";
import * as React from 'react';
export default {
  ContrastChecker,
  PageHeader,
  Heading3,
  Paragraph,
  colors,
  GridItem,
  GridContainer,
  ValidationErrorFilledIcon,
  ValidationSuccessFilledIcon,
  React
};