import { Props } from 'docz';
import { FileUpload } from '@entur/fileupload';
import { ImageIcon } from '@entur/icons';
import { Playground } from '~/components/playground/Playground';
import PageHeader from '~/components/PageHeader';
import { ImportStatement } from '~/components/ImportStatement';
import { Heading4 } from '@entur/typography';
import * as React from 'react';
export default {
  Props,
  FileUpload,
  ImageIcon,
  Playground,
  PageHeader,
  ImportStatement,
  Heading4,
  React
};