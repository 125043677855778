import { Props } from 'docz';
import { Heading2, Paragraph, Label, Link } from '@entur/typography';
import { fontWeights } from '@entur/tokens';
import { ExpandablePanel } from '@entur/expand';
import PageHeader from '~/components/PageHeader';
import { ImportStatement } from '~/components/ImportStatement';
import { ComponentPreview } from '~/components/ComponentPreview';
import { GridContainer } from '@entur/grid';
import chips from "../../../../../content/components/komponenterBilder/Chip.jpg";
import button from "../../../../../content/components/komponenterBilder/Button.jpg";
import FAB from "../../../../../content/components/komponenterBilder/FAB.jpg";
import iconButton from "../../../../../content/components/komponenterBilder/Icon button.jpg";
import squareButton from "../../../../../content/components/komponenterBilder/Square button.jpg";
import Checkbox from "../../../../../content/components/komponenterBilder/Checkbox.jpg";
import Datepicker from "../../../../../content/components/komponenterBilder/Datepicker.jpg";
import Dropdown from "../../../../../content/components/komponenterBilder/Dropdown.jpg";
import FileUpload from "../../../../../content/components/komponenterBilder/File upload.jpg";
import MultiSelect from "../../../../../content/components/komponenterBilder/Multi select.jpg";
import Radio from "../../../../../content/components/komponenterBilder/Radio.jpg";
import RadioPanel from "../../../../../content/components/komponenterBilder/Radio panel.jpg";
import SegmentedControl from "../../../../../content/components/komponenterBilder/Segmented control.jpg";
import Switch from "../../../../../content/components/komponenterBilder/Switch.jpg";
import TextArea from "../../../../../content/components/komponenterBilder/Text area.jpg";
import TextField from "../../../../../content/components/komponenterBilder/Text field.jpg";
import Timepicker from "../../../../../content/components/komponenterBilder/Timepicker.jpg";
import Breadcrumbs from "../../../../../content/components/komponenterBilder/Breadcrumbs.jpg";
import OverflowMenu from "../../../../../content/components/komponenterBilder/Overflow menu.jpg";
import Pagination from "../../../../../content/components/komponenterBilder/Pagination.jpg";
import SideNavigation from "../../../../../content/components/komponenterBilder/Side navigation.jpg";
import Stepper from "../../../../../content/components/komponenterBilder/Stepper.jpg";
import TopNavigation from "../../../../../content/components/komponenterBilder/Top navigation.jpg";
import Card from "../../../../../content/components/komponenterBilder/Card.jpg";
import Contrast from "../../../../../content/components/komponenterBilder/Contrast.jpg";
import Drawer from "../../../../../content/components/komponenterBilder/Drawer.jpg";
import Expandable from "../../../../../content/components/komponenterBilder/Expandable.jpg";
import MediaCard from "../../../../../content/components/komponenterBilder/Media card.jpg";
import Modal from "../../../../../content/components/komponenterBilder/Modal.jpg";
import Table from "../../../../../content/components/komponenterBilder/Table.jpg";
import Tabs from "../../../../../content/components/komponenterBilder/Tabs.jpg";
import Alert from "../../../../../content/components/komponenterBilder/Alert.jpg";
import Badge from "../../../../../content/components/komponenterBilder/Badge.jpg";
import Loader from "../../../../../content/components/komponenterBilder/Loader.jpg";
import Popover from "../../../../../content/components/komponenterBilder/Popover.jpg";
import Skeleton from "../../../../../content/components/komponenterBilder/Skeleton.jpg";
import Tooltip from "../../../../../content/components/komponenterBilder/Tooltip.jpg";
import TravelHeader from "../../../../../content/components/komponenterBilder/Travel header.jpg";
import TravelLeg from "../../../../../content/components/komponenterBilder/Travel leg.jpg";
import TravelSwitch from "../../../../../content/components/komponenterBilder/Travel switch.jpg";
import TravelTag from "../../../../../content/components/komponenterBilder/Travel tag.jpg";
import * as React from 'react';
export default {
  Props,
  Heading2,
  Paragraph,
  Label,
  Link,
  fontWeights,
  ExpandablePanel,
  PageHeader,
  ImportStatement,
  ComponentPreview,
  GridContainer,
  chips,
  button,
  FAB,
  iconButton,
  squareButton,
  Checkbox,
  Datepicker,
  Dropdown,
  FileUpload,
  MultiSelect,
  Radio,
  RadioPanel,
  SegmentedControl,
  Switch,
  TextArea,
  TextField,
  Timepicker,
  Breadcrumbs,
  OverflowMenu,
  Pagination,
  SideNavigation,
  Stepper,
  TopNavigation,
  Card,
  Contrast,
  Drawer,
  Expandable,
  MediaCard,
  Modal,
  Table,
  Tabs,
  Alert,
  Badge,
  Loader,
  Popover,
  Skeleton,
  Tooltip,
  TravelHeader,
  TravelLeg,
  TravelSwitch,
  TravelTag,
  React
};