import { Props } from 'docz';
import { CheckboxPanel, Fieldset } from '@entur/form';
import { PrimaryButton } from '@entur/button';
import { ForwardIcon } from '@entur/icons';
import { Label } from '@entur/typography';
import { Playground } from '~/components/playground/Playground';
import { ImportStatement } from '~/components/ImportStatement';
import PageHeader from '~/components/PageHeader';
import { inputpanel } from "../../../../../content/components/skjemaelementer/props";
import * as React from 'react';
export default {
  Props,
  CheckboxPanel,
  Fieldset,
  PrimaryButton,
  ForwardIcon,
  Label,
  Playground,
  ImportStatement,
  PageHeader,
  inputpanel,
  React
};