import { Props } from 'docz';
import { Contrast, useContrast } from '@entur/layout';
import { LeadParagraph, Paragraph } from '@entur/typography';
import { Playground } from '~/components/playground/Playground';
import { ImportStatement } from '~/components/ImportStatement';
import PageHeader from '~/components/PageHeader';
import * as React from 'react';
export default {
  Props,
  Contrast,
  useContrast,
  LeadParagraph,
  Paragraph,
  Playground,
  ImportStatement,
  PageHeader,
  React
};