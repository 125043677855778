import PageHeader from '~/components/PageHeader';
import * as tokens from '@entur/tokens';
import { AddIcon, SubtractIcon } from '@entur/icons';
import { Heading4, Paragraph } from '@entur/typography';
import brukertestingVerktoy from "../../../../../content/kom-i-gang/for-designere/images/brukertestingVerktoy.png";
import "../../../../../content/kom-i-gang/for-designere/brukertesting.scss";
import * as React from 'react';
export default {
  PageHeader,
  tokens,
  AddIcon,
  SubtractIcon,
  Heading4,
  Paragraph,
  brukertestingVerktoy,
  React
};