import PageHeader from '~/components/PageHeader';
import { NumberedList, ListItem, Paragraph } from '@entur/typography';
import { EmphasizedText, Heading2 } from '@entur/typography';
import entur1 from "../../../../../content/identitet/introduksjon/ENTUR.. 1.png";
import paalag from "../../../../../content/identitet/introduksjon/paalag.png";
import lettaaforstaa from "../../../../../content/identitet/introduksjon/lettaaforstaa.png";
import framiskoa from "../../../../../content/identitet/introduksjon/framiskoa.png";
import "../../../../../content/identitet/introduksjon/verdier.scss";
import * as React from 'react';
export default {
  PageHeader,
  NumberedList,
  ListItem,
  Paragraph,
  EmphasizedText,
  Heading2,
  entur1,
  paalag,
  lettaaforstaa,
  framiskoa,
  React
};