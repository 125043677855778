import { Props } from 'docz';
import { Playground } from '~/components/playground/Playground';
import PageHeader from '~/components/PageHeader';
import InlineComponent from '~/components/InlineComponent';
import { ImportStatement } from '~/components/ImportStatement';
import { SecondaryButton } from '@entur/button';
import { Loader } from '@entur/loader';
import * as React from 'react';
export default {
  Props,
  Playground,
  PageHeader,
  InlineComponent,
  ImportStatement,
  SecondaryButton,
  Loader,
  React
};