import { Props, Link } from 'docz';
import { MultiSelect } from '@entur/dropdown';
import { SmallExpandableAlertBox } from '@entur/alert';
import { CodeText, Link as LinkText } from '@entur/typography';
import { ExpandableText } from '@entur/expand';
import { Playground } from '~/components/playground/Playground';
import { ImportStatement } from '~/components/ImportStatement';
import { CodeBlock } from '~/components/CodeBlock';
import PageHeader from '~/components/PageHeader';
import { FILTERS, dropdownItems, countries, cities, fuzzyMatch } from "../../../../../content/components/skjemaelementer/dropdownItems";
import * as React from 'react';
export default {
  Props,
  Link,
  MultiSelect,
  SmallExpandableAlertBox,
  CodeText,
  LinkText,
  ExpandableText,
  Playground,
  ImportStatement,
  CodeBlock,
  PageHeader,
  FILTERS,
  dropdownItems,
  countries,
  cities,
  fuzzyMatch,
  React
};