import { Props, Link } from 'docz';
import { SideNavigation, SideNavigationItem, SideNavigationGroup, CollapsibleSideNavigation } from '@entur/menu';
import { LeadParagraph, Paragraph, Link as LinkText } from '@entur/typography';
import { UserIcon, StatsIcon, SettingsIcon, RSSIcon, AddIcon, MenuIcon, CalendarIcon } from '@entur/icons';
import '@entur/icons';
import { Playground } from '~/components/playground/Playground';
import PageHeader from '~/components/PageHeader';
import { ImportStatement } from '~/components/ImportStatement';
import * as React from 'react';
export default {
  Props,
  Link,
  SideNavigation,
  SideNavigationItem,
  SideNavigationGroup,
  CollapsibleSideNavigation,
  LeadParagraph,
  Paragraph,
  LinkText,
  UserIcon,
  StatsIcon,
  SettingsIcon,
  RSSIcon,
  AddIcon,
  MenuIcon,
  CalendarIcon,
  Playground,
  PageHeader,
  ImportStatement,
  React
};