import { Props } from 'docz';
import { now, today, isWeekend } from '@internationalized/date';
import { DatePicker, DateField, Calendar, TimePicker, NativeDatePicker, nativeDateToDateValue, nativeDateToTimeValue, timeOrDateValueToNativeDate } from '@entur/datepicker';
import { SmallAlertBox } from '@entur/alert';
import { CodeText } from '@entur/typography';
import { Playground } from '~/components/playground/Playground';
import { ImportStatement } from '~/components/ImportStatement';
import PageHeader from '~/components/PageHeader';
import { CodeBlock } from '~/components/CodeBlock';
import { datepicker } from "../../../../../content/components/skjemaelementer/props";
import "../../../../../content/components/skjemaelementer/styles.scss";
import * as React from 'react';
export default {
  Props,
  now,
  today,
  isWeekend,
  DatePicker,
  DateField,
  Calendar,
  TimePicker,
  NativeDatePicker,
  nativeDateToDateValue,
  nativeDateToTimeValue,
  timeOrDateValueToNativeDate,
  SmallAlertBox,
  CodeText,
  Playground,
  ImportStatement,
  PageHeader,
  CodeBlock,
  datepicker,
  React
};