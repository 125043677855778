import { Props, Link } from 'docz';
import { BusIcon, BicycleIcon } from '@entur/icons';
import { TravelTag } from '@entur/travel';
import { Playground } from '~/components/playground/Playground';
import PageHeader from '~/components/PageHeader';
import { ImportStatement } from '~/components/ImportStatement';
import { traveltag } from "../../../../../content/components/reise/props";
import "../../../../../content/components/reise/traveltag.css";
import * as React from 'react';
export default {
  Props,
  Link,
  BusIcon,
  BicycleIcon,
  TravelTag,
  Playground,
  PageHeader,
  ImportStatement,
  traveltag,
  React
};