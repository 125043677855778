import brukAssets from "../../../../../content/tokens/fargetokens/images/brukAssets.gif";
import brukMenu from "../../../../../content/tokens/fargetokens/images/brukMenu.gif";
import brukFlateBase from "../../../../../content/tokens/fargetokens/images/brukFlateBase.png";
import brukBase from "../../../../../content/tokens/fargetokens/images/brukBase.png";
import dontTokens from "../../../../../content/tokens/fargetokens/images/dont-tokens.png";
import doTokens from "../../../../../content/tokens/fargetokens/images/do-tokens.png";
import primitive from "../../../../../content/tokens/fargetokens/images/primitive.png";
import semantic from "../../../../../content/tokens/fargetokens/images/semantic.png";
import component from "../../../../../content/tokens/fargetokens/images/component.png";
import base from "../../../../../content/tokens/fargetokens/images/base.png";
import { BaseCard } from '@entur/layout';
import BaseCardDesignEntur from '~/components/BaseCardDesignEntur';
import PageHeader from '~/components/PageHeader';
import { Heading5, SmallText } from '@entur/typography';
import { GridContainer, GridItem } from '@entur/grid';
import { BannerAlertBox } from '@entur/alert';
import * as React from 'react';
export default {
  brukAssets,
  brukMenu,
  brukFlateBase,
  brukBase,
  dontTokens,
  doTokens,
  primitive,
  semantic,
  component,
  base,
  BaseCard,
  BaseCardDesignEntur,
  PageHeader,
  Heading5,
  SmallText,
  GridContainer,
  GridItem,
  BannerAlertBox,
  React
};