import { MediaCard } from '@entur/layout';
import { Props } from 'docz';
import { Playground } from '~/components/playground/Playground';
import PageHeader from '~/components/PageHeader';
import { ImportStatement } from '~/components/ImportStatement';
import { DesktopIcon } from '@entur/icons';
import mediacard from "../../../../../content/components/layout-og-flater/MediaCard.png";
import * as React from 'react';
export default {
  MediaCard,
  Props,
  Playground,
  PageHeader,
  ImportStatement,
  DesktopIcon,
  mediacard,
  React
};