import { FloatingButton } from '@entur/button';
import { Label } from '@entur/typography';
import { space } from '@entur/tokens';
import { Props } from 'docz';
import { AddIcon, CheckIcon, RightArrowIcon, ForwardIcon } from '@entur/icons';
import { Playground } from '~/components/playground/Playground';
import PageHeader from '~/components/PageHeader';
import { ImportStatement } from '~/components/ImportStatement';
import { DoDontGroup, DoDontCard } from '~/components/DoDont';
import FabExample from "../../../../../content/components/knapper/FabExample.png";
import FabDo from "../../../../../content/components/knapper/FabDo.png";
import FabDont from "../../../../../content/components/knapper/FabDont.png";
import { flytendeknapper } from "../../../../../content/components/knapper/props";
import * as React from 'react';
export default {
  FloatingButton,
  Label,
  space,
  Props,
  AddIcon,
  CheckIcon,
  RightArrowIcon,
  ForwardIcon,
  Playground,
  PageHeader,
  ImportStatement,
  DoDontGroup,
  DoDontCard,
  FabExample,
  FabDo,
  FabDont,
  flytendeknapper,
  React
};