import { Props } from 'docz';
import { PrimaryButton } from '@entur/button';
import { Modal, ModalContent, ModalOverlay } from '@entur/modal';
import { Heading2, Paragraph } from '@entur/typography';
import { Playground } from '~/components/playground/Playground';
import PageHeader from '~/components/PageHeader';
import { ImportStatement } from '~/components/ImportStatement';
import * as React from 'react';
export default {
  Props,
  PrimaryButton,
  Modal,
  ModalContent,
  ModalOverlay,
  Heading2,
  Paragraph,
  Playground,
  PageHeader,
  ImportStatement,
  React
};