import { Props } from 'docz';
import { now } from '@internationalized/date';
import { SegmentedControl, MultipleSegmentedControl, SegmentedChoice, TextField } from '@entur/form';
import { GridContainer, GridItem } from '@entur/grid';
import { BannerAlertBox } from '@entur/alert';
import { DatePicker, TimePicker } from '@entur/datepicker';
import { Playground } from '~/components/playground/Playground';
import { Dropdown } from '@entur/dropdown';
import { ImportStatement } from '~/components/ImportStatement';
import { CodeText } from '@entur/typography';
import { BoldIcon, ItalicIcon, UnderlineIcon } from '@entur/icons';
import PageHeader from '~/components/PageHeader';
import { CodeBlock } from '~/components/CodeBlock';
import * as React from 'react';
export default {
  Props,
  now,
  SegmentedControl,
  MultipleSegmentedControl,
  SegmentedChoice,
  TextField,
  GridContainer,
  GridItem,
  BannerAlertBox,
  DatePicker,
  TimePicker,
  Playground,
  Dropdown,
  ImportStatement,
  CodeText,
  BoldIcon,
  ItalicIcon,
  UnderlineIcon,
  PageHeader,
  CodeBlock,
  React
};